/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.js
 * - /npm/@splidejs/splide@4.1.3/dist/js/splide.min.js
 * - /npm/gsap@3.11.3/dist/gsap.min.js
 * - /npm/gsap@3.12.2/dist/ScrollTrigger.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
